/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const OPEN_MOBILE_MENU = 'OPEN_MOBILE_MENU';
export const CLOSE_MOBILE_MENU = 'CLOSE_MOBILE_MENU';

export const FILTER_REMOVE_TERM = 'FILTER_REMOVE_TERM';

export const ACTIVATE_FRONTPAGE_VIDEO = 'ACTIVATE_FRONTPAGE_VIDEO';
export const DEACTIVATE_FRONTPAGE_VIDEO = 'DEACTIVATE_FRONTPAGE_VIDEO';
export const FRONTPAGE_VIDEO_TICK = 'FRONTPAGE_VIDEO_TICK';
export const FRONTPAGE_VIDEO_SKIPTO = 'FRONTPAGE_VIDEO_SKIPTO';
export const FRONTPAGE_VIDEO_START_DRAG = 'FRONTPAGE_VIDEO_START_DRAG';
export const FRONTPAGE_VIDEO_END_DRAG = 'FRONTPAGE_VIDEO_END_DRAG';

export const TRANSITION_OUT = 'TRANSITION_OUT';

